<template>
  <section id="month-report">
    <b-card>
      <b-row>
        <b-col xl="6" md="6">
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon icon="CalendarIcon" />
            </b-input-group-prepend>
            <flat-pickr v-model="selectedMonthYear" class="form-control" :config="{ dateFormat: 'M-Y', }"
              @on-change="changeMonthYear" />
          </b-input-group>
        </b-col>
      </b-row>
    </b-card>
    <b-overlay :show="show" spinner-variant="primary" variant="transparent" blur="3px" rounded="sm">
      <b-row>
        <b-col xl="6">
          <b-card no-body>
            <b-card-header>
              <h4 class="mb-0">
                Number of new/existing customers
                <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer"
                  id="popover-number-new-existing-customers" />
              </h4>
              <b-popover target="popover-number-new-existing-customers" triggers="hover" placement="bottom">
                <span>No data</span>
              </b-popover>
            </b-card-header>
            <ECharts ref="numberCustomersChart" :options="numberCustomersChart" style="width: 100%; height: 400px;" />
          </b-card>
        </b-col>
        <b-col xl="6">
          <b-card no-body>
            <b-card-header>
              <h4 class="mb-0">
                Turnover of new/existing customers
                <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer"
                  id="popover-turnover-new-existing-customers" />
              </h4>
              <b-popover target="popover-turnover-new-existing-customers" triggers="hover" placement="bottom">
                <span>No data</span>
              </b-popover>
            </b-card-header>
            <ECharts ref="turnoverCustomersChart" :options="turnoverCustomersChart"
              style="width: 100%; height: 400px;" />
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col xl="6">
          <b-card no-body>
            <b-card-header>
              <h4 class="mb-0">
                Top customers
                <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer" id="popover-top-customers" />
              </h4>
              <b-popover target="popover-top-customers" triggers="hover" placement="bottom">
                <span>No data</span>
              </b-popover>
            </b-card-header>
            <ECharts ref="topCustomersChart" :options="topCustomersChart" style="width: 100%; height: 400px;" />
          </b-card>
        </b-col>
        <b-col xl="6">
          <b-card no-body>
            <b-card-header>
              <h4 class="mb-0">
                Top platforms
                <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer" id="popover-top-platforms" />
              </h4>
              <b-popover target="popover-top-platforms" triggers="hover" placement="bottom">
                <span>No data</span>
              </b-popover>
            </b-card-header>
            <ECharts ref="topPlatformsChart" :options="topPlatformsChart" style="width: 100%; height: 400px;" />
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col xl="6">
          <b-card no-body>
            <b-card-header>
              <h4 class="mb-0">
                Top countries
                <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer" id="popover-top-countries" />
              </h4>
              <b-popover target="popover-top-countries" triggers="hover" placement="bottom">
                <span>No data</span>
              </b-popover>
            </b-card-header>
            <ECharts ref="topCountriesChart" :options="topCountriesChart" style="width: 100%; height: 400px;" />
          </b-card>
        </b-col>
        <b-col xl="6">
          <b-card no-body>
            <b-card-header>
              <h4 class="mb-0">
                Top customer groups
                <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer"
                  id="popover-top-customer-groups" />
              </h4>
              <b-popover target="popover-top-customer-groups" triggers="hover" placement="bottom">
                <span>No data</span>
              </b-popover>
            </b-card-header>
            <ECharts ref="topCustomerGroupsChart" :options="topCustomerGroupsChart"
              style="width: 100%; height: 400px;" />
          </b-card>
        </b-col>
      </b-row>
    </b-overlay>
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardHeader,
  BPopover,
  BOverlay,
  BInputGroup,
  BInputGroupPrepend,
} from 'bootstrap-vue';
import flatPickr from 'vue-flatpickr-component';
import axios from 'axios';
import ECharts from 'vue-echarts';
import 'echarts';

import 'echarts/lib/chart/bar';
import 'echarts/lib/chart/pie';
import 'echarts/lib/component/tooltip';

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BPopover,
    BOverlay,
    BInputGroup,
    BInputGroupPrepend,
    ECharts,
    flatPickr,
  },
  data() {
    return {
      show: true,
      selectedMonthYear: `${new Date().toLocaleString('en-us', { month: 'short' })}-${new Date().getFullYear()}`,
      queryParams: {},
      numberCustomersChart: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: function (params) {
            let result = params[0].name + '<br/>';
            params.forEach(param => {
              result += param.value ? `${param.marker} ${param.seriesName}: ${parseFloat(param.value).toLocaleString('de-DE')}<br/>` : '';
            });
            return result;
          }
        },
        legend: {},
        xAxis: {
          type: 'category',
          data: [],
          axisTick: {
            alignWithLabel: true
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: function (value) {
              return value.toLocaleString('de-DE');
            }
          }
        },
        series: [],
      },
      turnoverCustomersChart: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: function (params) {
            let result = params[0].name + '<br/>';
            params.forEach(param => {
              result += param.value ? `${param.marker} ${param.seriesName}: ${parseFloat(param.value).toLocaleString('de-DE')} €<br/>` : '';
            });
            return result;
          }
        },
        legend: {},
        xAxis: {
          type: 'category',
          data: [],
          axisTick: {
            alignWithLabel: true
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: function (value) {
              return value.toLocaleString('de-DE');
            }
          }
        },
        series: [],
      },
      topCustomersChart: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: function (params) {
            let result = params[0].name + '<br/>';
            params.forEach(param => {
              result += param.value ? `${param.marker} ${param.seriesName}: ${parseFloat(param.value).toLocaleString('de-DE')} €<br/>` : '';
            });
            return result;
          }
        },
        legend: {},
        xAxis: {
          type: 'category',
          data: [],
          axisTick: {
            alignWithLabel: true
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: function (value) {
              return value.toLocaleString('de-DE');
            }
          }
        },
        series: [],
      },
      topPlatformsChart: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: function (params) {
            let result = params[0].name + '<br/>';
            params.forEach(param => {
              result += param.value ? `${param.marker} ${param.seriesName}: ${parseFloat(param.value).toLocaleString('de-DE')} €<br/>` : '';
            });
            return result;
          }
        },
        legend: {},
        xAxis: {
          type: 'category',
          data: [],
          axisTick: {
            alignWithLabel: true
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: function (value) {
              return value.toLocaleString('de-DE');
            }
          }
        },
        series: [],
      },
      topCountriesChart: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: function (params) {
            let result = params[0].name + '<br/>';
            params.forEach(param => {
              result += param.value ? `${param.marker} ${param.seriesName}: ${parseFloat(param.value).toLocaleString('de-DE')} €<br/>` : '';
            });
            return result;
          }
        },
        legend: {},
        xAxis: {
          type: 'category',
          data: [],
          axisTick: {
            alignWithLabel: true
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: function (value) {
              return value.toLocaleString('de-DE');
            }
          }
        },
        series: [],
      },
      topCustomerGroupsChart: {
        tooltip: {
          trigger: 'item',
          formatter: function (params) {
            return params.name + '<br/>' + `${params.marker} ${params.seriesName}: ${params.value.toLocaleString('de-DE')} €<br/>`;
          }
        },
        series: [
          {
            name: 'Amount',
            type: 'pie',
            radius: ['40%', '70%'],
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 10,
              borderColor: '#fff',
              borderWidth: 2
            },
            label: {
              show: false,
              position: 'center'
            },
            emphasis: {
              label: {
                show: true,
                fontSize: 40,
                fontWeight: 'bold'
              }
            },
            labelLine: {
              show: false
            },
            data: []
          }
        ],
      },
    }
  },
  async created() {
    const monthYear = new Date(this.selectedMonthYear);
    const fromDate = monthYear;
    const toDate = new Date(monthYear.getFullYear(), monthYear.getMonth() + 1, 0);
    this.queryParams.from_date = `${fromDate.getFullYear()}-${fromDate.getMonth() + 1}-${fromDate.getDate()}`;
    this.queryParams.to_date = `${toDate.getFullYear()}-${toDate.getMonth() + 1}-${toDate.getDate()}`;

    try {
      await this.getData();
    } catch (error) {
      if (error.response.status === 401) {
        this.$router.push({ name: 'login' });
      }
    }
  },
  methods: {
    isEmpty(obj) {
      return Object.keys(obj).length === 0;
    },
    updateCharts(data) {
      this.numberCustomersChart.xAxis.data = ['New Customers', 'Existing Customers'];
      this.numberCustomersChart.series[0] = {
        name: 'Previous year',
        type: 'bar',
        emphasis: {
          focus: 'series'
        },
        label: {
          show: true,
          position: 'inside',
          distance: 5,
          align: 'center',
          verticalAlign: 'middle',
          rotate: 90,
          formatter: params => params.value ? params.value.toLocaleString('de-DE') : '',
          fontSize: 10,
          rich: {
            name: {}
          },
        },
        data: [data[0].data.results.length, data[2].data.results.length],
      };
      this.numberCustomersChart.series[1] = {
        name: 'Selected year',
        type: 'bar',
        emphasis: {
          focus: 'series'
        },
        label: {
          show: true,
          position: 'inside',
          distance: 5,
          align: 'center',
          verticalAlign: 'middle',
          rotate: 90,
          formatter: params => params.value ? params.value.toLocaleString('de-DE') : '',
          fontSize: 10,
          rich: {
            name: {}
          },
        },
        data: [data[1].data.results.length, data[3].data.results.length],
      };

      this.turnoverCustomersChart.xAxis.data = ['New Customers', 'Existing Customers'];
      this.turnoverCustomersChart.series[0] = {
        name: 'Previous year',
        type: 'bar',
        emphasis: {
          focus: 'series'
        },
        label: {
          show: true,
          position: 'inside',
          distance: 5,
          align: 'center',
          verticalAlign: 'middle',
          rotate: 90,
          formatter: params => params.value ? params.value.toLocaleString('de-DE') : '',
          fontSize: 10,
          rich: {
            name: {}
          },
        },
        data: [data[0].data.results.reduce((total, obj) => obj.turnover + total, 0).toFixed(2),
        data[2].data.results.reduce((total, obj) => obj.turnover + total, 0).toFixed(2)],
      };
      this.turnoverCustomersChart.series[1] = {
        name: 'Selected year',
        type: 'bar',
        emphasis: {
          focus: 'series'
        },
        label: {
          show: true,
          position: 'inside',
          distance: 5,
          align: 'center',
          verticalAlign: 'middle',
          rotate: 90,
          formatter: params => params.value ? params.value.toLocaleString('de-DE') : '',
          fontSize: 10,
          rich: {
            name: {}
          },
        },
        data: [data[1].data.results.reduce((total, obj) => obj.turnover + total, 0).toFixed(2),
        data[3].data.results.reduce((total, obj) => obj.turnover + total, 0).toFixed(2)],
      };

      this.topCustomersChart.xAxis.data = data[4].data.results.map(item => item.company_name);
      this.topCustomersChart.series[0] = {
        name: 'Turnover',
        type: 'bar',
        emphasis: {
          focus: 'series'
        },
        label: {
          show: true,
          position: 'inside',
          distance: 5,
          align: 'center',
          verticalAlign: 'middle',
          rotate: 90,
          formatter: params => params.value ? params.value.toLocaleString('de-DE') : '',
          fontSize: 10,
          rich: {
            name: {}
          },
        },
        data: data[4].data.results.map(item => item.turnover),
      };

      this.topPlatformsChart.xAxis.data = data[5].data.results.map(item => item.platform);
      this.topPlatformsChart.series[0] = {
        name: 'Turnover',
        type: 'bar',
        emphasis: {
          focus: 'series'
        },
        label: {
          show: true,
          position: 'inside',
          distance: 5,
          align: 'center',
          verticalAlign: 'middle',
          rotate: 90,
          formatter: params => params.value ? params.value.toLocaleString('de-DE') : '',
          fontSize: 10,
          rich: {
            name: {}
          },
        },
        data: data[5].data.results.map(item => item.turnover),
      };

      this.topCountriesChart.xAxis.data = data[6].data.results.map(item => item.country);
      this.topCountriesChart.series[0] = {
        name: 'Turnover',
        type: 'bar',
        emphasis: {
          focus: 'series'
        },
        label: {
          show: true,
          position: 'inside',
          distance: 5,
          align: 'center',
          verticalAlign: 'middle',
          rotate: 90,
          formatter: params => params.value ? params.value.toLocaleString('de-DE') : '',
          fontSize: 10,
          rich: {
            name: {}
          },
        },
        data: data[6].data.results.map(item => item.turnover),
      };

      this.topCustomerGroupsChart.series[0].data = data[7].data.results.map(item => {
        return {
          value: item.turnover,
          name: item.customer_group,
        };
      });
    },
    async makeRequest(url, params) {
      return axios.get(url, {
        headers: {
          Authorization: `JWT ${this.$store.state.jwt}`,
          'Content-Type': 'application/json',
        },
        params,
      });
    },
    async getData() {
      this.show = true;
      const queryParamsPY = {};
      const selectedDate = new Date(this.queryParams.from_date);
      const fromDate = new Date(selectedDate.getFullYear() - 1, selectedDate.getMonth(), 1);
      const toDate = new Date(selectedDate.getFullYear() - 1, selectedDate.getMonth() + 1, 0);
      queryParamsPY.from_date = `${fromDate.getFullYear()}-${fromDate.getMonth() + 1}-${fromDate.getDate()}`;
      queryParamsPY.to_date = `${toDate.getFullYear()}-${toDate.getMonth() + 1}-${toDate.getDate()}`;

      try {
        const response = await axios.all([
          this.makeRequest(`${process.env.VUE_APP_ROOT_API}/new-customers/`, this.queryParams),
          this.makeRequest(`${process.env.VUE_APP_ROOT_API}/new-customers/`, queryParamsPY),
          this.makeRequest(`${process.env.VUE_APP_ROOT_API}/existing-customers/`, this.queryParams),
          this.makeRequest(`${process.env.VUE_APP_ROOT_API}/existing-customers/`, queryParamsPY),
          this.makeRequest(`${process.env.VUE_APP_ROOT_API}/orders-by-customers/`, this.queryParams),
          this.makeRequest(`${process.env.VUE_APP_ROOT_API}/orders-by-platform/`, this.queryParams),
          this.makeRequest(`${process.env.VUE_APP_ROOT_API}/orders-by-country/`, this.queryParams),
          this.makeRequest(`${process.env.VUE_APP_ROOT_API}/turnover-by-customer-groups/`, this.queryParams),
        ]);
        this.updateCharts(response);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      } finally {
        this.show = false;
      }
    },
    async changeMonthYear() {
      const monthYear = new Date(this.selectedMonthYear);
      const fromDate = monthYear;
      const toDate = new Date(monthYear.getFullYear(), monthYear.getMonth() + 1, 0);
      this.queryParams.from_date = `${fromDate.getFullYear()}-${fromDate.getMonth() + 1}-${fromDate.getDate()}`;
      this.queryParams.to_date = `${toDate.getFullYear()}-${toDate.getMonth() + 1}-${toDate.getDate()}`;
      await this.getData();

      this.$nextTick(() => {
        this.$refs.numberCustomersChart.refresh();
        this.$refs.turnoverCustomersChart.refresh();
        this.$refs.topCustomersChart.refresh();
        this.$refs.topPlatformsChart.refresh();
        this.$refs.topCountriesChart.refresh();
        this.$refs.topCustomerGroupsChart.refresh();
      });
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
