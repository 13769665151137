<template>
  <section id="open-offers">
    <b-card>
      <b-row>
        <b-col xl="6" md="6">
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon icon="CalendarIcon" />
            </b-input-group-prepend>
            <flat-pickr v-model="dateRange" placeholder="Date Range" class="form-control" :config="{ mode: 'range' }"
              @on-change="changeDateRange" />
          </b-input-group>
        </b-col>
        <b-col xl="6" md="6">
          <v-select v-model="selectedUser" label="title" placeholder="Employee" :options="allUsers"
            @input="changeSelectedUser" />
        </b-col>
      </b-row>
    </b-card>
    <b-row>
      <b-col xl="6">
        <b-overlay :show="showVacationsOverview" spinner-variant="primary" variant="transparent" blur="3px"
          rounded="sm">
          <b-card no-body>
            <b-card-header>
              <h4 class="mb-0">
                Vacations
                <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer" id="popover-vacations" />
              </h4>
              <b-popover target="popover-vacations" triggers="hover" placement="bottom">
                <span>No data</span>
              </b-popover>
            </b-card-header>
            <b-table striped hover responsive class="position-relative" :current-page="vacationsTable.currentPage"
              :per-page="10" :items="vacationsTable.items" :fields="vacationsTable.fields"
              :sort-by.sync="vacationsTable.sortBy" :sort-desc.sync="vacationsTable.sortDesc"
              :sort-direction="vacationsTable.sortDirection" :filter="vacationsTable.filter"
              :filter-included-fields="vacationsTable.filterOn" :tbody-tr-class="isVacationActiveNow" />

            <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
              <!-- pagination -->
              <div>
                <b-pagination v-model="vacationsTable.currentPage" :total-rows="vacationsTable.totalRows" first-number
                  last-number prev-class="prev-item" next-class="next-item" class="mb-0">
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </div>
            </b-card-body>
          </b-card>
        </b-overlay>
      </b-col>
      <b-col xl="6">
        <b-overlay :show="showSickdaysOverview" spinner-variant="primary" variant="transparent" blur="3px" rounded="sm">
          <b-card no-body>
            <b-card-header>
              <h4 class="mb-0">
                Sickdays
                <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer" id="popover-sickdays" />
              </h4>
              <b-popover target="popover-sickdays" triggers="hover" placement="bottom">
                <span>No data</span>
              </b-popover>
            </b-card-header>
            <b-table striped hover responsive class="position-relative" :current-page="sickdaysTable.currentPage"
              :per-page="10" :items="sickdaysTable.items" :fields="sickdaysTable.fields"
              :sort-by.sync="sickdaysTable.sortBy" :sort-desc.sync="sickdaysTable.sortDesc"
              :sort-direction="sickdaysTable.sortDirection" :filter="sickdaysTable.filter"
              :filter-included-fields="sickdaysTable.filterOn" :tbody-tr-class="isSickdayActiveNow" />

            <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
              <!-- pagination -->
              <div>
                <b-pagination v-model="sickdaysTable.currentPage" :total-rows="sickdaysTable.totalRows" first-number
                  last-number prev-class="prev-item" next-class="next-item" class="mb-0">
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </div>
            </b-card-body>
          </b-card>
        </b-overlay>
      </b-col>
    </b-row>
    <b-row>
      <b-col xl="6">
        <b-overlay :show="showVacationsOverview" spinner-variant="primary" variant="transparent" blur="3px"
          rounded="sm">
          <b-card no-body>
            <b-card-header>
              <h4 class="mb-0">
                Vacations per employee
                <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer"
                  id="popover-vacations-employee" />
              </h4>
              <b-popover target="popover-vacations-employee" triggers="hover" placement="bottom">
                <span>No data</span>
              </b-popover>
            </b-card-header>
            <b-table striped hover responsive class="position-relative"
              :current-page="vacationsPerEmployeeTable.currentPage" :per-page="10"
              :items="vacationsPerEmployeeTable.items" :fields="vacationsPerEmployeeTable.fields"
              :sort-by.sync="vacationsPerEmployeeTable.sortBy" :sort-desc.sync="vacationsPerEmployeeTable.sortDesc"
              :sort-direction="vacationsPerEmployeeTable.sortDirection" :filter="vacationsPerEmployeeTable.filter"
              :filter-included-fields="vacationsPerEmployeeTable.filterOn" />

            <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
              <!-- pagination -->
              <div>
                <b-pagination v-model="vacationsPerEmployeeTable.currentPage"
                  :total-rows="vacationsPerEmployeeTable.totalRows" first-number last-number prev-class="prev-item"
                  next-class="next-item" class="mb-0">
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </div>
            </b-card-body>
          </b-card>
        </b-overlay>
      </b-col>
      <b-col xl="6">
        <b-overlay :show="showSickdaysOverview" spinner-variant="primary" variant="transparent" blur="3px" rounded="sm">
          <b-card no-body>
            <b-card-header>
              <h4 class="mb-0">
                Sickdays per employee
                <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer"
                  id="popover-sickdays-employee" />
              </h4>
              <b-popover target="popover-sickdays-employee" triggers="hover" placement="bottom">
                <span>No data</span>
              </b-popover>
            </b-card-header>
            <b-table striped hover responsive class="position-relative"
              :current-page="sickdaysPerEmployeeTable.currentPage" :per-page="10"
              :items="sickdaysPerEmployeeTable.items" :fields="sickdaysPerEmployeeTable.fields"
              :sort-by.sync="sickdaysPerEmployeeTable.sortBy" :sort-desc.sync="sickdaysPerEmployeeTable.sortDesc"
              :sort-direction="sickdaysPerEmployeeTable.sortDirection" :filter="sickdaysPerEmployeeTable.filter"
              :filter-included-fields="sickdaysPerEmployeeTable.filterOn" />

            <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
              <!-- pagination -->
              <div>
                <b-pagination v-model="sickdaysPerEmployeeTable.currentPage"
                  :total-rows="sickdaysPerEmployeeTable.totalRows" first-number last-number prev-class="prev-item"
                  next-class="next-item" class="mb-0">
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </div>
            </b-card-body>
          </b-card>
        </b-overlay>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BTable,
  BPagination,
  BCardBody,
  BCardHeader,
  BPopover,
  BCard,
  BOverlay,
  BInputGroup,
  BInputGroupPrepend,
  BForm,
  BFormGroup,
  BFormInput,
  BFormTextarea,
} from 'bootstrap-vue';
import vSelect from 'vue-select';
import flatPickr from 'vue-flatpickr-component';
import axios from 'axios';

const currentDate = new Date();
const threeMonthsAgo = new Date();
const threeMonthsAhead = new Date();
threeMonthsAgo.setMonth(currentDate.getMonth() - 3);
threeMonthsAhead.setMonth(currentDate.getMonth() + 4);
const formatMonth = (date) => String(date.getMonth() + 1).padStart(2, '0');
const formatDate = (date) => String(date.getDate()).padStart(2, '0');

export default {
  components: {
    BRow,
    BCol,
    BTable,
    BPagination,
    BCard,
    BCardBody,
    BCardHeader,
    BPopover,
    BOverlay,
    BInputGroup,
    BInputGroupPrepend,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    vSelect,
    flatPickr,
  },
  data() {
    return {
      showVacationsOverview: true,
      showSickdaysOverview: true,
      oldDateRange: `${threeMonthsAgo.getFullYear()}-${formatMonth(threeMonthsAgo)}-01 to ${threeMonthsAhead.getFullYear()}-${formatMonth(threeMonthsAhead)}-${formatDate(threeMonthsAhead)}`,
      dateRange: `${threeMonthsAgo.getFullYear()}-${formatMonth(threeMonthsAgo)}-01 to ${threeMonthsAhead.getFullYear()}-${formatMonth(threeMonthsAhead)}-${formatDate(threeMonthsAhead)}`,
      selectedUser: null,
      allUsers: [],
      queryParamsVacations: {},
      queryParamsSickdays: {},
      vacationsTable: {
        totalRows: 1,
        currentPage: 1,
        sortBy: 'id',
        sortDesc: true,
        sortDirection: 'desc',
        filter: null,
        filterOn: [],
        infoModal: {
          id: 'info-modal',
          title: '',
          content: '',
        },
        fields: [
          { key: 'full_name', label: 'full name', sortable: true },
          { key: 'title', label: 'title', sortable: true },
          {
            key: 'start_date',
            label: 'start at',
            sortable: true,
            formatter: value => {
              const new_date = new Date(value);
              if (value) {
                return new_date.toLocaleDateString('de-DE');
              }
              return '';
            },
          },
          {
            key: 'end_date',
            label: 'end at',
            sortable: true,
            formatter: value => {
              const new_date = new Date(value);
              if (value) {
                return new_date.toLocaleDateString('de-DE');
              }
              return '';
            },
          },
          { key: 'total_days', label: 'used days', sortable: true },
          { key: 'total_days_date_range', label: 'used days in date range', sortable: true },
        ],
        /* eslint-disable global-require */
        items: [],
      },
      sickdaysTable: {
        totalRows: 1,
        currentPage: 1,
        sortBy: 'id',
        sortDesc: true,
        sortDirection: 'desc',
        filter: null,
        filterOn: [],
        infoModal: {
          id: 'info-modal',
          title: '',
          content: '',
        },
        fields: [
          { key: 'full_name', label: 'full name', sortable: true },
          { key: 'title', label: 'title', sortable: true },
          {
            key: 'start_date',
            label: 'start at',
            sortable: true,
            formatter: value => {
              const new_date = new Date(value);
              if (value) {
                return new_date.toLocaleDateString('de-DE');
              }
              return '';
            },
          },
          {
            key: 'end_date',
            label: 'end at',
            sortable: true,
            formatter: value => {
              const new_date = new Date(value);
              if (value) {
                return new_date.toLocaleDateString('de-DE');
              }
              return '';
            },
          },
          { key: 'total_days', label: 'used days', sortable: true },
          { key: 'total_days_date_range', label: 'used days in date range', sortable: true },
        ],
        /* eslint-disable global-require */
        items: [],
      },
      vacationsPerEmployeeTable: {
        totalRows: 1,
        currentPage: 1,
        sortBy: 'full_name',
        sortDesc: true,
        sortDirection: 'desc',
        filter: null,
        filterOn: [],
        infoModal: {
          id: 'info-modal',
          title: '',
          content: '',
        },
        fields: [
          { key: 'full_name', label: 'full name', sortable: true },
          { key: 'total_days', label: 'used days', sortable: true },
          { key: 'total_days_date_range', label: 'used days in date range', sortable: true },
        ],
        /* eslint-disable global-require */
        items: [],
      },
      sickdaysPerEmployeeTable: {
        totalRows: 1,
        currentPage: 1,
        sortBy: 'full_name',
        sortDesc: true,
        sortDirection: 'desc',
        filter: null,
        filterOn: [],
        infoModal: {
          id: 'info-modal',
          title: '',
          content: '',
        },
        fields: [
          { key: 'full_name', label: 'full name', sortable: true },
          { key: 'total_days', label: 'used days', sortable: true },
          { key: 'total_days_date_range', label: 'used days in date range', sortable: true },
        ],
        /* eslint-disable global-require */
        items: [],
      },
    }
  },
  async created() {
    const dateRange = this.dateRange.split(' to ');
    this.queryParamsVacations.from_date = dateRange[0];
    this.queryParamsVacations.to_date = dateRange[1];
    this.queryParamsSickdays.from_date = dateRange[0];
    this.queryParamsSickdays.to_date = dateRange[1];

    try {
      await this.getAllUsers();
      await this.getVacations();
      await this.getSickdays();
    } catch (error) {
      if (error.response.status === 401) {
        this.$router.push({ name: 'login' });
      }
    }
  },
  methods: {
    isVacationActiveNow(item, type) {
      if (!item || type !== 'row') return;
      if (item.is_active_now === true) return 'table-success';
    },
    isSickdayActiveNow(item, type) {
      if (!item || type !== 'row') return;
      if (item.is_active_now === true) return 'table-success';
    },
    groupByFullNameAndSum(data) {
      return data.reduce((acc, item) => {
        // Find if there's already an entry for this full_name
        let existing = acc.find(entry => entry.full_name === item.full_name);

        if (existing) {
          // If found, sum the total_days and total_days_date_range
          existing.total_days += item.total_days;
          existing.total_days_date_range += item.total_days_date_range;
        } else {
          // If not found, create a new entry
          acc.push({
            full_name: item.full_name,
            total_days: item.total_days,
            total_days_date_range: item.total_days_date_range
          });
        }

        return acc;
      }, []);
    },
    async makeRequest(url, params) {
      return axios.get(url, {
        headers: {
          Authorization: `JWT ${this.$store.state.jwt}`,
          'Content-Type': 'application/json',
        },
        params,
      });
    },
    async getAllUsers() {
      try {
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/all-users/`, {});
        this.allUsers = response.data.map(item => ({ id: item.id, title: item.full_name }));
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      }
    },
    async getVacations() {
      this.showVacationsOverview = true;
      try {
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/approved-vacations/`, this.queryParamsVacations);
        const results = response.data;
        if (results.length != 0) {
          this.vacationsTable.items = results;
          this.vacationsTable.totalRows = results.length * 2;

          let resultsGroupedByFullName = this.groupByFullNameAndSum(results);
          this.vacationsPerEmployeeTable.items = resultsGroupedByFullName;
          this.vacationsPerEmployeeTable.totalRows = resultsGroupedByFullName.length * 2;
        }
        else {
          this.vacationsTable.items = [];
          this.vacationsTable.totalRows = 0;
          this.vacationsPerEmployeeTable.items = [];
          this.vacationsPerEmployeeTable.totalRows = 0;
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      } finally {
        this.showVacationsOverview = false;
      }
    },
    async getSickdays() {
      this.showSickdaysOverview = true;
      try {
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/approved-sickdays/`, this.queryParamsSickdays);
        const results = response.data;
        if (results.length != 0) {
          this.sickdaysTable.items = results;
          this.sickdaysTable.totalRows = results.length * 2;

          let resultsGroupedByFullName = this.groupByFullNameAndSum(results);
          this.sickdaysPerEmployeeTable.items = resultsGroupedByFullName;
          this.sickdaysPerEmployeeTable.totalRows = resultsGroupedByFullName.length * 2;
        }
        else {
          this.sickdaysTable.items = [];
          this.sickdaysTable.totalRows = 0;
          this.sickdaysPerEmployeeTable.items = [];
          this.sickdaysPerEmployeeTable.totalRows = 0;
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      } finally {
        this.showSickdaysOverview = false;
      }
    },
    async changeDateRange() {
      const dateRange = this.dateRange.split(' to ');
      if (dateRange.length > 1 && this.dateRange != this.oldDateRange) {
        this.queryParamsVacations.from_date = dateRange[0];
        this.queryParamsVacations.to_date = dateRange[1];
        this.queryParamsSickdays.from_date = dateRange[0];
        this.queryParamsSickdays.to_date = dateRange[1];

        await this.getVacations();
        await this.getSickdays();

        this.oldDateRange = this.dateRange;
      }
    },
    async changeSelectedUser() {
      if (this.selectedUser) {
        this.queryParamsVacations.user_id = this.selectedUser.id;
        this.queryParamsSickdays.user_id = this.selectedUser.id;

        await this.getVacations();
        await this.getSickdays();
      }
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>