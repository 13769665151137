export default [
  {
    header: 'Customer Support',
    icon: 'PhoneIcon',
    children: [
      {
        title: 'Home',
        route: 'home',
      },
      {
        title: 'Support Overview',
        route: 'support-overview',
      },
      {
        title: 'Open Offers',
        route: 'open-offers',
      },
      {
        title: 'Top Customers',
        route: 'top-customers',
      },
      {
        title: 'Products',
        route: 'products',
      },
      {
        title: 'Calculation Special Items',
        route: 'calculation-special-items',
      },
      {
        title: 'Conversion Rate',
        route: 'conversion-rate',
      },
      {
        title: 'Avg. Delivery Time',
        route: 'avg-delivery-time',
      },
      {
        title: 'Phone Statistics',
        route: 'phone-statistics',
      },
      {
        title: 'Email Statistics',
        route: 'email-statistics',
      },
    ],
  },
  {
    header: 'Purchase Department',
    icon: 'MailIcon',
    children: [
      {
        title: 'Delayed Orders',
        route: 'delayed-unconfirmed-orders',
      },
      {
        title: 'Open Invoices',
        route: 'open-invoices',
      },
      {
        title: 'Open Suppliers Orders',
        route: 'open-suppliers-orders',
      },
      {
        title: 'Not Ordered Products',
        route: 'not-ordered-products',
      },
      {
        title: 'Suppliers Statistics',
        route: 'suppliers-statistics',
      },
      {
        title: 'Price Adjustments',
        route: 'price-adjustments',
      },
    ],
  },
  {
    header: 'Product Management',
    icon: 'PackageIcon',
    children: [
      {
        title: 'Category Development',
        route: 'category-development',
      },
      {
        title: 'Category Statistics',
        route: 'category-statistics',
      },
      {
        title: 'Brands Statistics',
        route: 'brands-statistics',
      },
      {
        title: 'Products Attributes',
        route: 'products-attributes',
      },
      {
        title: 'Product ABC Analysis',
        route: 'product-abc',
      },
      {
        title: 'Not Sold Products',
        route: 'not-sold-products',
      },
      {
        title: 'Active/Inactive Products',
        route: 'active-inactive-products',
      },
      {
        title: 'Outdated Products',
        route: 'outdated-products',
      },
      {
        title: 'New Articles H+H',
        route: 'new-articles-hh',
      },
      {
        title: 'All Scraped Items',
        route: 'all-scraped-items',
      },
      {
        title: 'Pricing Tool',
        route: 'pricing-tool',
      },
    ],
  },
  {
    header: 'Controlling',
    icon: 'SlidersIcon',
    children: [
      {
        title: 'Analytics',
        route: 'analytics',
      },
      {
        title: 'Orders Analytics',
        route: 'orders-analytics',
      },
      {
        title: 'Customers Analytics',
        route: 'customers-analytics',
      },
      {
        title: 'Finance Report',
        route: 'finance-report',
      },
      {
        title: 'Month Report',
        route: 'month-report',
      },
      {
        title: 'Stock Value',
        route: 'stock-value',
      },
      {
        title: 'Liquidity',
        route: 'liquidity',
      },
      {
        title: 'Revenue Margin',
        route: 'revenue-margin',
      },
      {
        title: 'Price Calculation',
        route: 'price-calculation',
      },
      {
        title: 'Orders',
        route: 'orders',
      },
      {
        title: 'Freight Charges',
        route: 'freight-charges',
      },
      {
        title: 'Suppliers Orders',
        route: 'suppliers-orders',
      },
      {
        title: 'Bookkeeping',
        route: 'bookkeeping',
      },
      {
        title: 'GMI',
        route: 'invoices-gmi',
      },
      {
        title: 'Payment Settings',
        route: 'payment-settings',
      },
      {
        title: 'Plan Numbers',
        route: 'plan-numbers',
      },
      {
        title: 'Performance Overview',
        route: 'performance-overview',
      },
      {
        title: 'Vacation/Sickday Overview',
        route: 'vacation-sickday-overview',
      },
      {
        title: 'Config AI',
        route: 'config-ai',
      },
    ],
  },
  {
    title: 'User Management',
    route: 'user-management',
    icon: 'UserIcon',
  },
  {
    title: 'Vacation Tracking',
    route: 'vacation-tracking',
    icon: 'CalendarIcon',
  },
]
