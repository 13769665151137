<template>
  <section id="active-inactive-products">
    <b-overlay :show="showInactiveProducts" spinner-variant="primary" variant="transparent" blur="3px" rounded="sm">
      <b-card no-body>
        <b-card-header>
          <h4 class="mb-0">
            Inactive items and available at suppliers
            <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer"
              id="popover-inactive-items-available-suppliers" />
          </h4>
          <b-popover target="popover-inactive-items-available-suppliers" triggers="hover" placement="bottom">
            <span>No data</span>
          </b-popover>
          <b-card-text class="font-medium-5 mb-0">
            <b-overlay :show="showExportInactiveProductsToExcelStart" spinner-variant="primary" variant="transparent"
              blur="3px" rounded="sm">
              <feather-icon icon="DownloadIcon" size="21" class="text-muted cursor-pointer"
                @click="exportInactiveProductsToExcel" />
            </b-overlay>
          </b-card-text>
        </b-card-header>
        <b-table striped hover responsive class="position-relative" :current-page="inactiveProductsTable.currentPage"
          :items="inactiveProductsTable.items" :fields="inactiveProductsTable.fields"
          :sort-by.sync="inactiveProductsTable.sortBy" :sort-desc.sync="inactiveProductsTable.sortDesc"
          :sort-direction="inactiveProductsTable.sortDirection" :filter="inactiveProductsTable.filter"
          :filter-included-fields="inactiveProductsTable.filterOn" @sort-changed="sortChangedInactiveProducts" />
        <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
          <!-- pagination -->
          <div>
            <b-pagination v-model="inactiveProductsTable.currentPage" :total-rows="inactiveProductsTable.totalRows"
              first-number last-number prev-class="prev-item" next-class="next-item" class="mb-0"
              @change="handleInactiveProductsTablePageChange">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </b-card-body>
      </b-card>
    </b-overlay>
    <b-overlay :show="showActiveProducts" spinner-variant="primary" variant="transparent" blur="3px" rounded="sm">
      <b-card no-body>
        <b-card-header>
          <h4 class="mb-0">
            Active items and not available at suppliers
            <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer"
              id="popover-active-items-not-available-suppliers" />
          </h4>
          <b-popover target="popover-active-items-not-available-suppliers" triggers="hover" placement="bottom">
            <span>No data</span>
          </b-popover>
          <b-card-text class="font-medium-5 mb-0">
            <b-overlay :show="showExportActiveProductsToExcelStart" spinner-variant="primary" variant="transparent"
              blur="3px" rounded="sm">
              <feather-icon icon="DownloadIcon" size="21" class="text-muted cursor-pointer"
                @click="exportActiveProductsToExcel" />
            </b-overlay>
          </b-card-text>
        </b-card-header>
        <b-table striped hover responsive class="position-relative" :current-page="activeProductsTable.currentPage"
          :items="activeProductsTable.items" :fields="activeProductsTable.fields"
          :sort-by.sync="activeProductsTable.sortBy" :sort-desc.sync="activeProductsTable.sortDesc"
          :sort-direction="activeProductsTable.sortDirection" :filter="activeProductsTable.filter"
          :filter-included-fields="activeProductsTable.filterOn" @sort-changed="sortChangedActiveProducts" />
        <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
          <!-- pagination -->
          <div>
            <b-pagination v-model="activeProductsTable.currentPage" :total-rows="activeProductsTable.totalRows"
              first-number last-number prev-class="prev-item" next-class="next-item" class="mb-0"
              @change="handleActiveProductsTablePageChange">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </b-card-body>
      </b-card>
    </b-overlay>
    <b-overlay :show="showVOREquivalentProducts" spinner-variant="primary" variant="transparent" blur="3px"
      rounded="sm">
      <b-card no-body>
        <b-card-header>
          <h4 class="mb-0">
            VOR equivalent items
            <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer"
              id="popover-vor-equivalent-items-available-suppliers" />
          </h4>
          <b-popover target="popover-vor-equivalent-items-available-suppliers" triggers="hover" placement="bottom">
            <span>No data</span>
          </b-popover>
          <b-card-text class="font-medium-5 mb-0">
            <b-overlay :show="showExportVOREquivalentProductsToExcelStart" spinner-variant="primary"
              variant="transparent" blur="3px" rounded="sm">
              <feather-icon icon="DownloadIcon" size="21" class="text-muted cursor-pointer"
                @click="exportVOREquivalentProductsToExcel" />
            </b-overlay>
          </b-card-text>
        </b-card-header>
        <b-table striped hover responsive class="position-relative"
          :current-page="vorEquivalentProductsTable.currentPage" :items="vorEquivalentProductsTable.items"
          :fields="vorEquivalentProductsTable.fields" :sort-by.sync="vorEquivalentProductsTable.sortBy"
          :sort-desc.sync="vorEquivalentProductsTable.sortDesc"
          :sort-direction="vorEquivalentProductsTable.sortDirection" :filter="vorEquivalentProductsTable.filter"
          :filter-included-fields="vorEquivalentProductsTable.filterOn"
          @sort-changed="sortChangedVOREquivalentProducts" />
        <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
          <!-- pagination -->
          <div>
            <b-pagination v-model="vorEquivalentProductsTable.currentPage"
              :total-rows="vorEquivalentProductsTable.totalRows" first-number last-number prev-class="prev-item"
              next-class="next-item" class="mb-0" @change="handleVOREquivalentProductsTablePageChange">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </b-card-body>
      </b-card>
    </b-overlay>
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BTable,
  BPagination,
  BCardBody,
  BCardHeader,
  BCardText,
  BPopover,
  BCard,
  BOverlay,
  BButton,
} from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
import axios from 'axios';
import * as XLSX from 'xlsx';

export default {
  components: {
    BRow,
    BCol,
    BTable,
    BPagination,
    BCardBody,
    BCardHeader,
    BCardText,
    BPopover,
    BCard,
    BOverlay,
    BButton,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      showInactiveProducts: true,
      showActiveProducts: true,
      showVOREquivalentProducts: true,
      showExportInactiveProductsToExcelStart: false,
      showExportActiveProductsToExcelStart: false,
      showExportVOREquivalentProductsToExcelStart: false,
      queryParamsInactiveProducts: {},
      queryParamsActiveProducts: {},
      queryParamsVOREquivalentProducts: {},
      inactiveProductsTable: {
        totalRows: 1,
        currentPage: 1,
        sortBy: 'sprice',
        sortDesc: true,
        sortDirection: 'desc',
        filter: null,
        filterOn: [],
        infoModal: {
          id: 'info-modal',
          title: '',
          content: '',
        },
        fields: [
          { key: 'sku', label: 'sku', sortable: true },
          { key: 'name', label: 'name', sortable: true },
          { key: 'supplier_sku', label: 'supplier sku', sortable: true },
          { key: 'supplier', label: 'supplier', sortable: true },
          {
            key: 'pprice',
            label: 'purchase price',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `${this.$formatCurrencyNumber(val)}`;
              } else {
                return '';
              }
            },
          },
          {
            key: 'sprice',
            label: 'sales price',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `${this.$formatCurrencyNumber(val)}`;
              } else {
                return '';
              }
            },
          },
          { key: 'jtl', label: 'jtl', sortable: true },
          { key: 'onlineshop', label: 'onlineshop', sortable: true },
          { key: 'stock_qty', label: 'stock qty', sortable: true },
          { key: 'jtl_supstock_qty', label: 'jtl supplier stock qty', sortable: true },
          { key: 'scraped_supstock_qty', label: 'scraped supplier stock qty', sortable: true },
        ],
        /* eslint-disable global-require */
        items: [],
      },
      activeProductsTable: {
        totalRows: 1,
        currentPage: 1,
        sortBy: 'sprice',
        sortDesc: true,
        sortDirection: 'desc',
        filter: null,
        filterOn: [],
        infoModal: {
          id: 'info-modal',
          title: '',
          content: '',
        },
        fields: [
          { key: 'sku', label: 'sku', sortable: true },
          { key: 'name', label: 'name', sortable: true },
          { key: 'supplier_sku', label: 'supplier sku', sortable: true },
          { key: 'supplier', label: 'supplier', sortable: true },
          {
            key: 'pprice',
            label: 'purchase price',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `${this.$formatCurrencyNumber(val)}`;
              } else {
                return '';
              }
            },
          },
          {
            key: 'sprice',
            label: 'sales price',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `${this.$formatCurrencyNumber(val)}`;
              } else {
                return '';
              }
            },
          },
          { key: 'jtl', label: 'jtl', sortable: true },
          { key: 'onlineshop', label: 'onlineshop', sortable: true },
          { key: 'stock_qty', label: 'stock qty', sortable: true },
          { key: 'supstock_qty', label: 'supplier stock qty', sortable: true },
        ],
        /* eslint-disable global-require */
        items: [],
      },
      vorEquivalentProductsTable: {
        totalRows: 1,
        currentPage: 1,
        sortBy: 'sprice',
        sortDesc: true,
        sortDirection: 'desc',
        filter: null,
        filterOn: [],
        infoModal: {
          id: 'info-modal',
          title: '',
          content: '',
        },
        fields: [
          { key: 'sku', label: 'sku', sortable: true },
          { key: 'name', label: 'name', sortable: true },
          { key: 'supplier_sku', label: 'supplier sku', sortable: true },
          { key: 'supplier', label: 'supplier', sortable: true },
          {
            key: 'pprice',
            label: 'purchase price',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `${this.$formatCurrencyNumber(val)}`;
              } else {
                return '';
              }
            },
          },
          {
            key: 'sprice',
            label: 'sales price',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `${this.$formatCurrencyNumber(val)}`;
              } else {
                return '';
              }
            },
          },
          { key: 'jtl', label: 'jtl', sortable: true },
          { key: 'onlineshop', label: 'onlineshop', sortable: true },
          { key: 'stock_qty', label: 'stock qty', sortable: true },
          { key: 'supstock_qty', label: 'supplier stock qty', sortable: true },
        ],
        /* eslint-disable global-require */
        items: [],
      },
    }
  },
  async created() {
    try {
      await this.getInactiveProducts();
      await this.getActiveProducts();
      await this.getVOREquivalentProducts();
    } catch (error) {
      if (error.response.status === 401) {
        this.$router.push({ name: 'login' });
      }
    }
  },
  methods: {
    async makeRequest(url, params) {
      return axios.get(url, {
        headers: {
          Authorization: `JWT ${this.$store.state.jwt}`,
          'Content-Type': 'application/json',
        },
        params,
      });
    },
    async getInactiveProducts() {
      this.showInactiveProducts = true;
      try {
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/inactive-products/`, this.queryParamsInactiveProducts);
        const results = response.data.results;
        if (Array.isArray(results) && results.length > 0) {
          this.inactiveProductsTable.items = results;
          this.inactiveProductsTable.totalRows = results[0].count * 2;
        }
        else {
          this.inactiveProductsTable.items = [];
          this.inactiveProductsTable.totalRows = 0;
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      } finally {
        this.showInactiveProducts = false;
      }
    },
    async getActiveProducts() {
      this.showActiveProducts = true;
      try {
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/active-products/`, this.queryParamsActiveProducts);
        const results = response.data.results;
        if (Array.isArray(results) && results.length > 0) {
          this.activeProductsTable.items = results;
          this.activeProductsTable.totalRows = results[0].count * 2;
        }
        else {
          this.activeProductsTable.items = [];
          this.activeProductsTable.totalRows = 0;
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      } finally {
        this.showActiveProducts = false;
      }
    },
    async getVOREquivalentProducts() {
      this.showVOREquivalentProducts = true;
      try {
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/vor-equivalent-products/`, this.queryParamsVOREquivalentProducts);
        const results = response.data.results;
        if (Array.isArray(results) && results.length > 0) {
          this.vorEquivalentProductsTable.items = results;
          this.vorEquivalentProductsTable.totalRows = results[0].count * 2;
        }
        else {
          this.vorEquivalentProductsTable.items = [];
          this.vorEquivalentProductsTable.totalRows = 0;
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      } finally {
        this.showVOREquivalentProducts = false;
      }
    },
    async sortChangedInactiveProducts(value) {
      if (value.sortDesc === true) {
        this.queryParamsInactiveProducts.ordering = `-${value.sortBy}`;
      } else {
        this.queryParamsInactiveProducts.ordering = value.sortBy;
      }
      await this.getInactiveProducts();
    },
    async sortChangedActiveProducts(value) {
      if (value.sortDesc === true) {
        this.queryParamsActiveProducts.ordering = `-${value.sortBy}`;
      } else {
        this.queryParamsActiveProducts.ordering = value.sortBy;
      }
      await this.getActiveProducts();
    },
    async sortChangedVOREquivalentProducts(value) {
      if (value.sortDesc === true) {
        this.queryParamsVOREquivalentProducts.ordering = `-${value.sortBy}`;
      } else {
        this.queryParamsVOREquivalentProducts.ordering = value.sortBy;
      }
      await this.getVOREquivalentProducts();
    },
    async handleInactiveProductsTablePageChange(value) {
      this.queryParamsInactiveProducts.page = value;
      await this.getInactiveProducts();
    },
    async handleActiveProductsTablePageChange(value) {
      this.queryParamsActiveProducts.page = value;
      await this.getActiveProducts();
    },
    async handleVOREquivalentProductsTablePageChange(value) {
      this.queryParamsVOREquivalentProducts.page = value;
      await this.getVOREquivalentProducts();
    },
    async exportInactiveProductsToExcel() {
      try {
        this.showExportInactiveProductsToExcelStart = true;
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/all-inactive-products/`, {});
        const exportedData = response.data.results;
        this.showExportInactiveProductsToExcelStart = false;
        // Convert the data to a worksheet
        let worksheet = XLSX.utils.json_to_sheet(exportedData);
        // Create a new workbook, and add the worksheet to it
        let workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet 1');
        // Export the workbook to an xlsx file
        XLSX.writeFile(workbook, 'InactiveItems.xlsx');
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      }
    },
    async exportActiveProductsToExcel() {
      try {
        this.showExportActiveProductsToExcelStart = true;
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/all-active-products/`, {});
        const exportedData = response.data.results;
        this.showExportActiveProductsToExcelStart = false;
        // Convert the data to a worksheet
        let worksheet = XLSX.utils.json_to_sheet(exportedData);
        // Create a new workbook, and add the worksheet to it
        let workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet 1');
        // Export the workbook to an xlsx file
        XLSX.writeFile(workbook, 'ActiveItems.xlsx');
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      }
    },
    async exportVOREquivalentProductsToExcel() {
      try {
        this.showExportVOREquivalentProductsToExcelStart = true;
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/all-vor-equivalent-products/`, {});
        const exportedData = response.data.results;
        this.showExportVOREquivalentProductsToExcelStart = false;
        // Convert the data to a worksheet
        let worksheet = XLSX.utils.json_to_sheet(exportedData);
        // Create a new workbook, and add the worksheet to it
        let workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet 1');
        // Export the workbook to an xlsx file
        XLSX.writeFile(workbook, 'VOREquivalentItems.xlsx');
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      }
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>